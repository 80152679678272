.contentList {
  list-style-type: none;
  margin: 32px;
  padding: 0;
}

.contentList a {
  color: var(--fuwBrandTextColor);
  text-decoration: none;
}

.contentList a:hover {
  text-decoration: underline;
}

.contentList h2 {
  margin: 1.5em 0 0.5em;
}

.inactive {
  color: var(--colorGray3);
}
